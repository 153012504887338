import Grid from "@mui/material/Grid";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";

export const Wrapper = styling(Grid)(({ theme }) => ({
  maxWidth: "1440px",
  padding: "0 100px",
  backgroundColor: Color.homepageGrey,

  [theme.breakpoints.down("tablet")]: {
    padding: "0 20px",
  },
}));

export const BannerHomepageWrapper = styling(Grid)(
  ({ theme, $alertBanner }) => ({
    backgroundColor: Color.homepageGrey,

    [theme.breakpoints.down("lg")]: {
      marginTop: $alertBanner ? "calc(44px + 82px)" : "0",
    },
    [theme.breakpoints.down("tablet")]: {
      marginTop: $alertBanner ? "calc(50px + 104px)" : "50px",
    },
  })
);
