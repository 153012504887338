import * as types from "../types/dialog_type";

const showInfoDialog = (props) => async (dispatch) => {
  return dispatch({
    type: types.SHOW_INFO_DIALOG,
    payload: props,
  });
};

const showInfoDialogActivelyAvailable = (props) => async (dispatch) => {
  return dispatch({
    type: types.SHOW_INFO_DIALOG_ACTIVELY_AVAILABLE,
    payload: props,
  });
};

const clearInfoContent = () => async (dispatch) => {
  return dispatch({
    type: types.CLEAR_INFO_CONTENT,
  });
};

const hideInfoDialog = () => async (dispatch) => {
  return dispatch({
    type: types.HIDE_INFO_DIALOG,
  });
};

const showConfirmationDialog = (props) => async (dispatch) => {
  return dispatch({
    type: types.SHOW_CONFIRMATION_DIALOG,
    payload: props,
  });
};

const clearConfirmationContent = () => async (dispatch) => {
  return dispatch({
    type: types.CLEAR_CONFIRMATION_CONTENT,
  });
};

const hideConfirmationDialog = () => async (dispatch) => {
  return dispatch({
    type: types.HIDE_CONFIRMATION_DIALOG,
  });
};

const updateNoCvDialogStatus = (status) => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.UPDATE_NO_CV_DIALOG_STATUS,
        status: status,
      })
    );
  });
};

//alert banner
const updateAlertBanner = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_ALERT_BANNER,
    payload: status,
  });
};

//For You Resume Dialog
const updateRefreshDialogStatus = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_REFRESH_DIALOG_STATUS,
    status: status,
  });
};

const updateForYouResumeDialogStatus = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_FOR_YOU_RESUME_DIALOG_STATUS,
    status: status,
  });
};

//post apply dialog
const updatePostApplyDialog =
  (params = {}) =>
  async (dispatch) => {
    return new Promise((resolve) => {
      return resolve(
        dispatch({
          type: types.UPDATE_POST_APPLY_DIALOG,
          status: params.status,
          scraped: params.scraped,
          jobGlobalId: params.jobGlobalId,
        })
      );
    });
  };

const updateBulkApplyState = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_BULK_APPLY_STATE,
    status: status,
  });
};

const updateJobAlertDialog = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_JOB_ALERT_DIALOG,
    status: status,
  });
};

const updateJobAlertsNotificationDialog = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_JOB_ALERT_NOTIFICATIONS_DIALOG,
    status: status,
  });
};

const updateJobAlertsDeleteDialog = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_JOB_ALERT_DELETE_DIALOG,
    status: status,
  });
};

const updateJobAlertsDownloadAppDialog = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_JOB_ALERT_DOWNLOAD_APP_DIALOG,
    status: status,
  });
};

const updateJobAlertVisitorEmailDialog = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_JOB_ALERT_VISITOR_EMAIL_DIALOG,
    status: status,
  });
};

const updateJobAlertVisitorUnsubscribeDialog = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_JOB_ALERT_VISITOR_UNSUBSCRIBE_DIALOG,
    status: status,
  });
};

const updateJobAlertCreateDialog = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_JOB_ALERT_CREATE_DIALOG,
    status: status,
  });
};

const updateJobAlertCreateDialogWithKeyword = (props) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    return resolve(
      dispatch({
        type: types.UPDATE_JOB_ALERT_CREATE_DIALOG_KEYWORD,
        keyword: props.keyword,
        jobAlertKeywordOrigin: props.origin,
      })
    );
  });
};

const updateJobDetailsVisitorJobAletDialog = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_JOB_DETAILS_VISITOR_JOB_ALERT_DIALOG,
    status: status,
  });
};

const updateVisitorJobAlertDialogDetails = (props) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_VISITOR_JOB_ALERT_DIALOG_DETAILS,
    payload: props,
  });
};

const updateVisitorJobAlertDialogOrigin = (props) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_VISITOR_JOB_ALERT_DIALOG_ORIGIN,
    origin: props,
  });
};

const updateSecondaryVisitorDialog = (params) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_SECONDARY_VISITOR_DIALOG,
    payload: params,
  });
};

const updateResumeConfirmationDialog = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_RESUME_CONFIRMATION_DIALOG,
    payload: status,
  });
};

const updateProfilePreviewDialog = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_PROFILE_PREVIEW_DIALOG,
    payload: status,
  });
};

const updateSignUpProfileDialog = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_SIGN_UP_PROFILE_DIALOG,
    payload: status,
  });
};

const updateResumeFeedbackDialog = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_RESUME_FEEDBACK_DIALOG,
    payload: status,
  });
};

const updateAshleyEditNoResumeDialog = (props) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_ASHLEY_EDIT_NO_RESUME_DIALOG,
    payload: props,
  });
};

const updateAshleyEditConfirmationDialog = (props) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_ASHLEY_EDIT_CONFIRMATION_DIALOG,
    payload: props,
  });
};

const updateAshleyEditDialog = (props) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_ASHLEY_EDIT_DIALOG,
    payload: props,
  });
};

const updateContactUsEmployer = (props) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_CONTACT_US_EMPLOYER_DIALOG,
    payload: props,
  });
};

const updateViewProductEmployer = (props) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_VIEW_PRODUCT_EMPLOYER_DIALOG,
    payload: props,
  });
};

const updateSGJobReminderDialog = (props) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_SG_JOB_REMINDER_DIALOG,
    payload: props,
  });
};

export {
  clearConfirmationContent,
  clearInfoContent,
  hideConfirmationDialog,
  hideInfoDialog,
  showConfirmationDialog,
  showInfoDialog,
  showInfoDialogActivelyAvailable,
  updateAlertBanner,
  updateAshleyEditConfirmationDialog,
  updateAshleyEditDialog,
  updateAshleyEditNoResumeDialog,
  updateBulkApplyState,
  updateContactUsEmployer,
  updateForYouResumeDialogStatus,
  updateJobAlertCreateDialog,
  updateJobAlertCreateDialogWithKeyword,
  updateJobAlertDialog,
  updateJobAlertVisitorEmailDialog,
  updateJobAlertVisitorUnsubscribeDialog,
  updateJobAlertsDeleteDialog,
  updateJobAlertsDownloadAppDialog,
  updateJobAlertsNotificationDialog,
  updateJobDetailsVisitorJobAletDialog,
  updateNoCvDialogStatus,
  updatePostApplyDialog,
  updateProfilePreviewDialog,
  updateRefreshDialogStatus,
  updateResumeConfirmationDialog,
  updateResumeFeedbackDialog,
  updateSGJobReminderDialog,
  updateSecondaryVisitorDialog,
  updateSignUpProfileDialog,
  updateViewProductEmployer,
  updateVisitorJobAlertDialogDetails,
  updateVisitorJobAlertDialogOrigin,
};
