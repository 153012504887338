import dayjs from "dayjs";
import {
  generateDeviceType,
  getGaSessionID,
} from "../../helpers/data_management";
import { setCountryApi } from "../../helpers/multicountry_management";
import { posthogJobApply } from "../../helpers/posthog_management";
import { sendTrackingEvent } from "../../helpers/tracking_management";
import api, { strapiCall } from "../../utils/api";
import { store } from "../stores/store";
import * as types from "../types/job_type";
import { updateSignInText, updateSignUpText } from "./navbar_action";

const jobListAttributes = `
id
title            
spotlight
keywordHighlight
keywordHighlightText   
imageMobile                 
jobType            
stateRegion
location
bookmark
boosted
haveApplied
salary
expired
slug
active    
externalJobUrl            
activeAt
gptSummary
skills
globalId
globalHireEnabled
globalHirePreferences
tracks {
  id
  title
}
company {
  id
  logo
  name
  registerName   
  slug
  hide
  benefits{
    id
    title
  }
}
scraped     
jobSlotType
minYearsExperience
maxYearsExperience
careerLevel
crossBorderData
crossedBorder
category
aggregatedCompanyName
`;

const jobAttributesV1 = `
  id
  title
  image
  spotlight
  keywordHighlight
  keywordHighlightText
  imageThumb
  imageMobile
  imagePosition
  imageSize
  jobType
  globalId
  globalHireEnabled
  globalHirePreferences
  tracks {
    id
    title
  }
  trackId
  stateRegion
  location
  description
  shortDescription
  requirements
  bookmark
  boosted
  haveApplied
  haveRejected
  createdAt
  salary
  expired
  slug
  active    
  externalJobUrl
  secondaryCompanyId
  activeAt
  gptSummary
  skills
  jobSlotType
  company {
    id
    name
    registerName
    typeId
    industry
    shortDescription
    coverImage
    coverImageThumb
    jobCount
    bookmark
    hide
    logo
    slug
    lastActiveAt
  }
  scraped
  structuredJobData
  careerLevel
  minYearsExperience
  maxYearsExperience
  crossBorderData
  crossedBorder
  category
  aggregatedCompanyName
`;

const jobAttributesWithCompanyAndProfileV1 = `
  id
  title
  skills
  minYearsExperience
  maxYearsExperience
  image
  imageThumb
  careerLevel
  imageMobile
  imagePosition
  imageSize
  jobType
  tracks {
    id
    title
  }
  trackId
  stateRegion
  location
  description
  shortDescription
  requirements
  bookmark
  haveApplied
  haveRejected
  createdAt
  salary
  expired
  boosted
  slug  
  active
  activeAt 
  externalJobUrl
  secondaryCompanyId
  spotlight
  boosted
  keywordHighlight
  structuredJobData
  gptSummary
  globalId
  globalHireEnabled
  globalHirePreferences
  company {
    id
    name
    recruitmentFirm
    registerName
    typeId
    industry
    logo
    shortDescription
    coverImage
    coverImageThumb
    jobCount
    bookmark
    slug
    lastActiveAt
    hide
    benefits {
      title
      description
      iconCode
    }
    profile {
      address
      latitude
      longitude
      descriptions {
        title
        body
      }
      images {
        cover
        image
      }
      videos {
        title
        category
        vimeoId
        quote
        personName
        personPosition
      }
      extras {
        title
        vimeoId
      }
    }
    sizeId
  }
  scraped
  crossBorderData
  crossedBorder
  category
  aggregatedCompanyName
`;

const jobAlertAttributes = `
  id
  title
  frequency
  medium
  owner_id
  created_at
  updated_at
  owner {
    id
    email
  }
`;

const v1JobAlertAttributes = `
  id
  title
  medium
  frequency
  yearOfExperience
  salary
  activate
  yearOfExperience
  salary
  activate
  ownerId
  createdAt
  updatedAt
  owner {
    id
    email
  }
`;

const getSpecialisation =
  (params = {}) =>
  async (dispatch) => {
    const payload = {
      query: `{
        specialisations
      }      
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          let specialisations = response?.data?.data?.specialisations || [];
          // Rename 'sub_categories' to 'subSpecialisations'
          specialisations = specialisations?.map((specialisation) => {
            const formattedSpecialisation = {
              ...specialisation,
              subSpecialisations: specialisation["sub_categories"] ?? [],
            };
            delete formattedSpecialisation["sub_categories"];
            return formattedSpecialisation;
          });
          return dispatch({
            type: types.GET_SPECIALISATION_SUCCEED,
            payload: specialisations,
          });
        }
      });
  };

const getSpecialisationWithoutRedux = (params = {}) => {
  const payload = {
    query: `{
        specialisations
      }      
      `,
  };

  return api
    .apiCall("/api/job_seeker/v1/graphql", params, payload)
    .then(async (response) => {
      if (response.status === 200) {
        let specialisations = response?.data?.data?.specialisations || [];
        // Rename 'sub_categories' to 'subSpecialisations'
        specialisations = specialisations?.map((specialisation) => {
          const formattedSpecialisation = {
            ...specialisation,
            subSpecialisations: specialisation["sub_categories"] ?? [],
          };
          delete formattedSpecialisation["sub_categories"];
          return formattedSpecialisation;
        });

        if (specialisations != null) {
          return specialisations;
        }
        if (specialisations === null) {
          return { specialisationsNotFound: true };
        }
      }
      return { specialisationsNotFound: true };
    });
};

/*
 * Params:
 * 1) canCancel
 * 2) cancelToken
 */
const getTracks =
  (params = {}) =>
  async (dispatch) => {
    const payload = {
      query: `{
        tracks {
          id
          title
          slug
        }
      }      
    `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const tracks = response.data.data.tracks;
          return dispatch({
            type: types.GET_TRACK_SUCCEEDED,
            payload: tracks,
          });
        }
      });
  };

const getStateRegions =
  (params = {}) =>
  async (dispatch, getState) => {
    const hasStateRegions = getState()?.jobs?.stateRegions?.length;
    if (hasStateRegions) {
      return;
    }

    // TODO: Fix: Strapi GraphQL suddenly only return 10 records
    // need to explicitly specify the limit
    const payload = {
      query: `
      {
        stateRegions {
          id
          state
          slug
        }
      }      
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const stateRegions = response.data.data?.stateRegions;
          return dispatch({
            type: types.GET_STATEREGION_SUCCEEDED,
            payload: stateRegions,
          });
        } else {
          return dispatch({
            type: types.GET_STATEREGION_FAILED,
          });
        }
      })
      .catch(() => {
        return dispatch({
          type: types.GET_STATEREGION_FAILED,
        });
      });
  };

const getJobTypes =
  (params = {}) =>
  async (dispatch) => {
    const payload = {
      query: `{
      jobTypes {
          id
          title
        }
      }      
    `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const jobTypes = response.data.data.jobTypes;
          return dispatch({
            type: types.GET_JOB_TYPE_SUCCEEDED,
            payload: jobTypes,
          });
        }
      });
  };

/*
 * Params:
 * 1) canCancel
 * 2) cancelToken
 */
const getExperienceLevels =
  (params = {}) =>
  async (dispatch) => {
    const payload = {
      query: `{
      experiences {
          id
          title
        }
      }      
    `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const experienceLevels = response.data.data.experiences;
          return dispatch({
            type: types.GET_EXPERIENCE_LEVEL_SUCCEEDED,
            payload: experienceLevels,
          });
        }
      });
  };

const getFeaturedJobs =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.FETCHING_FEATURED_JOBS });
    const payload = {
      query: `{
        featuredJobs(first: 10) {
          nodes {
            id
            title
          }
        }
      }  
    `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const getFeaturedJobs = response.data.data.featuredJobs.nodes;

          return dispatch({
            type: types.FETCH_FEATURED_JOBS_SUCCEED,
            payload: getFeaturedJobs ? getFeaturedJobs : [],
          });
        } else {
          return dispatch({
            type: types.FETCH_FEATURED_JOBS_FAILED,
          });
        }
      });
  };

const getCuratedJobs =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.FETCHING_CURATED_JOBS });

    let payload;

    // Use recommendedJobs query if user is logged in
    if (params.isTitle) {
      payload = {
        query: `{
          recommendedJobs(first:10) {     
            nodes { 
              ${jobListAttributes} 
              image
              tracks {
                id
                title
              }
              careerLevel
              category
            }
          }
        }
        `,
      };
      // Else use curatedJobs query if user is not logged in
    } else {
      let trackInput = params.trackIds
        ? `, trackIds: ${JSON.stringify(params.trackIds)}`
        : "";

      payload = {
        query: `{
      curatedJobs(first:10 ${trackInput}){
        nodes { 
          ${jobListAttributes}   
          image
          tracks {
            id
            title
            }           
          }
          category
        }
      }      
    `,
      };
    }

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (
            response.data?.data?.curatedJobs?.nodes ||
            response.data?.data?.recommendedJobs?.nodes
          ) {
            const getCuratedJobs =
              response.data.data.curatedJobs?.nodes ||
              response.data.data.recommendedJobs?.nodes;

            return dispatch({
              type: types.FETCH_CURATED_JOBS_SUCCEED,
              payload: getCuratedJobs ? getCuratedJobs : [],
            });
          }

          return dispatch({
            type: types.FETCH_CURATED_JOBS_FAILED,
          });
        } else {
          return dispatch({
            type: types.FETCH_CURATED_JOBS_FAILED,
          });
        }
      });
  };

const clearJobList = () => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.CLEAR_JOB_LIST,
      })
    );
  });
};

const clearJobSuggestion = () => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.CLEAR_JOB_SUGGESTION,
      })
    );
  });
};

const clearBulkAppliedJobs = () => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.CLEAR_BULK_APPLIED_JOBS,
      })
    );
  });
};

const clearAppliedJobs = () => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.CLEAR_APPLIED_JOBS,
      })
    );
  });
};

const clearPendingJobs = () => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.CLEAR_PENDING_JOBS,
      })
    );
  });
};

const clearBookmarkJobList = () => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.CLEAR_JOB_BOOKMARK_LIST,
      })
    );
  });
};

const updateBookmarkedJobInList = (job) => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.UPDATE_BOOKMARKED_JOB,
        job: job,
      })
    );
  });
};

const updateInternshipFilterButtonStatus = (params) => async (dispatch) => {
  return new Promise((resolve) => {
    const internshipFilterButtonStatus = params;
    return resolve(
      dispatch({
        type: types.UPDATE_INTERNSHIP_FILTER_BUTTON_STATUS,
        internshipFilterButtonStatus: internshipFilterButtonStatus,
      })
    );
  });
};

/*
 * Params:
 * 1) endCursor
 * 2) trackIds
 * 3) stateRegions
 * 4) jobTypeIds
 * 5) experienceIds
 * 6) expectedSalary
 * 7) canCancel
 * 8) cancelToken
 */

const getJobs =
  (params = {}, pagination = {}, next, prev) =>
  async (dispatch, getState) => {
    dispatch({ type: types.FETCHING_ALL_JOBS, state: true });

    let keywordInput = params.keyword ? `keyword: "${params.keyword}",` : "";

    let trackInput =
      params.specialisations || params.trackIds
        ? `trackIds: ${JSON.stringify(
            params.specialisations || params.trackIds
          )},`
        : "";

    let stateRegionInput = params.stateRegions
      ? `stateRegions: ${JSON.stringify(params.stateRegions)},`
      : "";

    let jobTypeInput = params.jobTypeIds
      ? `jobTypeIds: ${JSON.stringify(params.jobTypeIds)},`
      : "";

    let experienceInput = params.experienceIds
      ? `experienceIds: ${JSON.stringify(params.experienceIds)},`
      : "";

    let expectedSalaryInput = `expectedSalary: ${
      params.expectedSalary ? params.expectedSalary : 0
    },`;

    let companyId = params.companyIds
      ? `companyIds: ${JSON.stringify(params.companyIds)},`
      : "";

    let globalHireEnabled = params.globalHire ? `globalHire: true,` : "";

    let queryInput =
      keywordInput +
      trackInput +
      stateRegionInput +
      jobTypeInput +
      experienceInput +
      companyId +
      expectedSalaryInput +
      globalHireEnabled;

    //Removing trailing ","
    if (queryInput.charAt(queryInput.length - 1) == ",") {
      queryInput = queryInput.substring(0, queryInput.length - 1);
    }

    /**
     * PAGINATION BEHAVIOUR
     *
     * Scenario 1 - going to NEXT page
     * {last: null, first: null, before: "null", after: "endCursor" }
     *
     * Scenario 2 - going to PREVIOUS page
     * {last: 30, first: null, before: "startCursor", after: "" }
     *
     * NOTE: after needs to be an empty string when going to previous page for it to work properly
     */

    const localEndCursor =
      pagination.endCursor === null ? "" : pagination.endCursor;
    const localStartCursor =
      pagination.startCursor === null ? "" : pagination.startCursor;

    const payload = {
      query: `{
        jobListsSearchResults(${queryInput}, last: ${pagination.last}, first: ${pagination.first}, before: "${localStartCursor}", after: "${localEndCursor}", showScraped: true) {
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          nodes {
            ${jobListAttributes}
            crossBorderData
            category
            aggregatedCompanyName
          }
        }
      }
    `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data.data?.jobListsSearchResults) {
            const { jobListsSearchResults } = response.data.data;

            dispatch({
              type: types.FETCH_JOBS_SUCCEED,
              payload: {
                jobList: jobListsSearchResults.nodes,
                refresh: params.refresh,
                currentLoadingJobsKey: params.currentLoadingJobsKey,
                totalJobsPages: jobListsSearchResults.totalPage,
                totalJobsCount: jobListsSearchResults.totalCount,
                pageInfo: jobListsSearchResults.pageInfo,
              },
            });
            return dispatch({ type: types.FETCHING_ALL_JOBS, state: false });
          }
        }

        // prevent the fetch job fail from triggering due to api cancel call
        if (response?.isCancelled !== true) {
          return dispatch({
            type: types.FETCH_JOBS_FAILED,
            currentLoadingJobsKey: params.currentLoadingJobsKey,
          });
        }
      })
      .catch(() => {
        return dispatch({
          type: types.FETCH_JOBS_FAILED,
          currentLoadingJobsKey: params.currentLoadingJobsKey,
        });
      });
  };

const getJobsWithoutRedux = async (params = {}, pagination = {}) => {
  let keywordInput = params.keyword ? `keyword: "${params.keyword}",` : "";

  let trackInput =
    params.specialisations || params.trackIds
      ? `trackIds: ${JSON.stringify(
          params.specialisations || params.trackIds
        )},`
      : "";

  let stateRegionInput = params.stateRegions
    ? `stateRegions: ${JSON.stringify(params.stateRegions)},`
    : "";

  let jobTypeInput = params.jobTypeIds
    ? `jobTypeIds: ${JSON.stringify(params.jobTypeIds)},`
    : "";

  let experienceInput = params.experienceIds
    ? `experienceIds: ${JSON.stringify(params.experienceIds)},`
    : "";

  let expectedSalaryInput = `expectedSalary: ${
    params.expectedSalary ? params.expectedSalary : 0
  },`;

  let companyId = params.companyIds
    ? `companyIds: ${JSON.stringify(params.companyIds)},`
    : "";

  let globalHireEnabled = params.globalHire ? `globalHire: true,` : "";

  let queryInput =
    keywordInput +
    trackInput +
    stateRegionInput +
    jobTypeInput +
    experienceInput +
    companyId +
    expectedSalaryInput +
    globalHireEnabled;

  //Removing trailing ","
  if (queryInput.charAt(queryInput.length - 1) == ",") {
    queryInput = queryInput.substring(0, queryInput.length - 1);
  }

  /**
   * PAGINATION BEHAVIOUR
   *
   * Scenario 1 - going to NEXT page
   * {last: null, first: null, before: "null", after: "endCursor" }
   *
   * Scenario 2 - going to PREVIOUS page
   * {last: 30, first: null, before: "startCursor", after: "" }
   *
   * NOTE: after needs to be an empty string when going to previous page for it to work properly
   */

  const localEndCursor =
    pagination.endCursor === null ? "" : pagination.endCursor;
  const localStartCursor =
    pagination.startCursor === null ? "" : pagination.startCursor;

  const payload = {
    query: `{
      jobListsSearchResults(${queryInput}, last: ${pagination.last}, first: ${pagination.first}, before: "${localStartCursor}", after: "${localEndCursor}", showScraped: true) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        nodes {
          ${jobListAttributes}
          crossBorderData
          category
          aggregatedCompanyName
        }
      }
    }
  `,
  };
  return api
    .apiCall("/api/job_seeker/v1/graphql", params, payload)
    .then(async (response) => {
      if (response.status === 200) {
        const { jobListsSearchResults } = response.data.data;
        if (jobListsSearchResults != null) {
          return {
            jobs: jobListsSearchResults?.nodes,
            pageInfo: jobListsSearchResults?.pageInfo,
          };
        }
        if (jobListsSearchResults === null) {
          return { jobNotFound: true };
        }
      }
      return { jobNotFound: true };
    });
};

const getJobsParams =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.GET_JOBS_PARAMS, payload: { jobsParams: params } });
  };

/*
 * Params:
 * 1) canCancel
 * 2) cancelToken
 */
const getJobsForSitemap = async (params = {}) => {
  const payload = {
    query: `{
      allJobsNotPaginated(limit:10000){
        slug
      }
    }`,
  };

  const response = await api.apiCall(
    "/api/job_seeker/v1/graphql",
    params,
    payload,
    true
  );
  if (response.status === 200) {
    const allJobs = response?.data?.data?.allJobsNotPaginated;
    if (allJobs != null) {
      return allJobs;
    }
    if (allJobs === null) {
      return null;
    }
    return null;
  }
};

const getJobsForJobsfeed = async (params = {}) => {
  const payload = {
    query: `{
      allJobs {
        nodes {
          slug
        }
      }
    }`,
  };

  const response = await api.apiCall(
    "/api/job_seeker/v1/graphql",
    params,
    payload,
    true
  );
  if (response.status === 200) {
    const allJobs = response?.data?.data?.allJobs?.nodes;
    if (allJobs != null) {
      return allJobs;
    }
    if (allJobs === null) {
      return null;
    }
    return null;
  }
};

const updateJobListPage =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_JOBS_LIST_PAGE,
      payload: params,
    });
  };

const updateFetchingJobListStatus =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_FETCHING_ALL_JOBS,
      payload: params,
    });
  };

/*
 * Params:
 * 1) canCancel
 * 2) cancelToken
 */
const getJobsForCima = async (params = {}) => {
  const payload = {
    query: `{
      cimaJobs {      
        nodes {
          id
          shortDescription
          description
          requirements
          title
          location
          tracks {
            title
          }
          image
          salary
          jobType
          updatedAt
          slug
          shortDescription
          requirements
          structuredJobData
          company {
            id
            name
            jobCount
            slug
            firstBillingAddress {
              country
              streetAddress
              town
              postcode
              state
            }
          }
        }
      }
    }`,
  };

  return api
    .apiCall("/api/job_seeker/v1/graphql", params, payload, true)
    .then(async (response) => {
      if (response.status === 200) {
        const jobs = response.data.data.nodes;
        if (jobs != null) {
          return jobs;
        }
        if (jobs === null) {
          return null;
        }
        return null;
      }
    });
};

const getJobV1 =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.GET_JOB });

    const payload = {
      query: `{
    job(id: "${params.jobId}"){
      ${jobAttributesWithCompanyAndProfileV1}
      crossBorderData
      category
      aggregatedCompanyName
    }
  }
`,
    };

    const response = await api.apiCall(
      "/api/job_seeker/v1/graphql",
      params,
      payload,
      true
    );
    if (response.status === 200) {
      const { job } = response.data.data;

      if (job != null) {
        return dispatch({
          type: types.GET_JOB_SUCCEED,
          job: job,
        });
      }
      if (job === null) {
        return dispatch({
          type: types.GET_JOB_FAILED,
          job: job,
          jobNotFound: true,
        });
      }
    }
    return await dispatch({ type: types.GET_JOB_FAILED, jobId: params.jobId });
  };

const getBulkJob =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.GET_BULK_JOB });

    const payload = {
      query: `{
        jobs(ids: ${JSON.stringify(params.jobIds)}) {
          ${jobListAttributes}
        }
      }`,
    };

    const response = await api.apiCall(
      "/api/job_seeker/v1/graphql",
      params,
      payload,
      true
    );

    if (response.status === 200) {
      const { jobs } = response.data.data;

      if (jobs != null) {
        return dispatch({
          type: types.GET_BULK_JOB_SUCCEED,
          jobs: jobs,
        });
      }
      if (jobs === null) {
        return dispatch({
          type: types.GET_BULK_JOB_FAILED,
          jobs: jobs,
          jobNotFound: true,
        });
      }
    }
  };
/*
 * Params:
 * 1) endCursor
 * 2) reload
 * 3) canCancel
 * 4) cancelToken
 */
const fetchJobBookmarkList =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: types.FETCH_JOB_BOOKMARK_LIST });

    const apiUrl = setCountryApi(params);

    const payload = {
      query: `{
        bookmarkJobs(after: "${params.endCursor}", first: ${params.first}) {
          pageInfo {
            startCursor
            endCursor
            hasPreviousPage
            hasNextPage
          }
          nodes {
            id
            createdAt
            job {
              ${jobListAttributes}
              image
              tracks {
                id
                title
              }
              category
            }
          }
        }
      }
      
    `,
    };

    return api.apiCall(apiUrl, params, payload).then(async (response) => {
      if (response.status === 200) {
        if (
          response.data &&
          response.data.data &&
          response.data.data.bookmarkJobs
        ) {
          const { nodes, pageInfo } = response.data.data.bookmarkJobs;
          if (nodes != null) {
            return dispatch({
              type: types.FETCH_JOB_BOOKMARK_LIST_SUCCEED,
              payload: { nodes, pageInfo },
            });
          }
        }
      }
      return dispatch({ type: types.FETCH_JOB_BOOKMARK_LIST_FAILED });
    });
  };

/*
 * Params:
 * 1) endCursor
 * 2) reload
 * 3) canCancel
 * 4) cancelToken
 */
const fetchAppliedJobs =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: types.FETCHING_APPLIED_JOBS });

    const apiUrl = setCountryApi(params);

    const payload = {
      query: `{
      appliedJobs(after: "${params.endCursor}", first: ${params.first}) {
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
        nodes {
          id
          applicationStatus
          status
          createdAt
          job {
            ${jobListAttributes}
            image
            tracks {
              id
              title
            }           
          }
        }
      }
    }
  `,
    };
    return api.apiCall(apiUrl, params, payload).then(async (response) => {
      if (response.status === 200) {
        if (
          response.data &&
          response.data.data &&
          response.data.data.appliedJobs
        ) {
          const { nodes, pageInfo } = response.data.data.appliedJobs;
          if (nodes != null) {
            return dispatch({
              type: types.FETCH_APPLIED_JOBS_SUCCEED,
              payload: { nodes, pageInfo },
            });
          }
        }
      }
      return dispatch({ type: types.FETCH_APPLIED_JOBS_FAILED });
    });
  };

/*
 * Params:
 * 1) endCursor
 * 2) reload
 * 3) canCancel
 * 4) cancelToken
 */
const fetchPendingJobs =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: types.FETCHING_PENDING_JOBS });
    const apiUrl = setCountryApi(params);

    const payload = {
      query: `{
      pendingJobs(after: "${params.endCursor}", first: ${params.first}) {
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
        nodes {
          id    
          createdAt
          job {
            ${jobListAttributes}
            image
            tracks {
              id
              title
            }           
          }
        }
      }
    }
  `,
    };

    return api.apiCall(apiUrl, params, payload).then(async (response) => {
      if (response.status === 200) {
        if (
          response.data &&
          response.data.data &&
          response.data.data.pendingJobs
        ) {
          const { nodes, pageInfo } = response.data.data.pendingJobs;
          if (nodes != null) {
            return dispatch({
              type: types.FETCH_PENDING_JOBS_SUCCEED,
              payload: { nodes, pageInfo },
            });
          }
        }
      }
      return dispatch({ type: types.FETCH_PENDING_JOBS_FAILED });
    });
  };

/*
 * Params:
 * 1) jobId
 * 2) canCancel
 * 3) cancelToken
 */

const bookmarkJob =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.BOOKMARK_JOB });

    const gaSessionId = getGaSessionID();

    const payload = {
      query: `
        mutation {
          bookmarkJob(input: {jobId: "${params.jobId}", sessionId: "${gaSessionId}"}) {
            bookmarkJob {
              id
              createdAt
              job {
                ${jobListAttributes}
              }
            }
          }
        }      
      `,
    };

    const response = await api.apiCall(
      "/api/job_seeker/v1/graphql",
      params,
      payload
    );

    if (response.status === 200) {
      const { bookmarkJob } = response?.data?.data;
      if (bookmarkJob != null && bookmarkJob != undefined) {
        let cJob;
        let cBookmarkJob = bookmarkJob.bookmarkJob;
        if (cBookmarkJob && cBookmarkJob.job) {
          cJob = cBookmarkJob.job;
        }
        // Custom event for saving/bookmarking jobs

        let categoryTag;

        if (
          cJob?.boosted === true &&
          cJob?.spotlight === true &&
          cJob?.keywordHighlight === true
        ) {
          categoryTag = "featured_spotlight_keyword";
        } else if (
          cJob?.boosted === true &&
          cJob?.spotlight === true &&
          !cJob?.keywordHighlight
        ) {
          categoryTag = "featured_spotlight";
        } else if (
          cJob?.boosted === true &&
          !cJob?.spotlight &&
          cJob?.keywordHighlight === true
        ) {
          categoryTag = "featured_keyword";
        } else if (
          !cJob?.boosted &&
          cJob?.spotlight === true &&
          cJob?.keywordHighlight === true
        ) {
          categoryTag = "spotlight_keyword";
        } else if (
          !cJob?.boosted &&
          !cJob?.spotlight &&
          cJob?.keywordHighlight === true
        ) {
          categoryTag = "keyword";
        } else if (
          cJob?.boosted === true &&
          !cJob?.spotlight &&
          !cJob?.keywordHighlight
        ) {
          categoryTag = "featured";
        } else if (
          !cJob?.boosted &&
          cJob?.spotlight === true &&
          !cJob?.keywordHighlight
        ) {
          categoryTag = "spotlight";
        }

        !params.appliedDialog &&
          params.origin &&
          params?.origin !== "for-you" &&
          sendTrackingEvent({
            event: `${
              cJob?.boosted
                ? `CE_saved-job-${params.origin}`
                : cJob?.secondaryCompanyId
                ? `${params.origin}-urgent-hiring-job-saved`
                : `CE_saved-job-${params.origin}`
            }`,
            "company-id": `${cJob?.company?.id}`,
            "job-id": `${cJob?.id}`,
            "job-title": cJob.title,
            spotlight: cJob?.spotlight ? true : false,
            featured: cJob?.boosted ? true : false,
            keyword: cJob?.keywordHighlight ? true : false,
          });

        params.appliedDialog === true &&
          sendTrackingEvent({
            event: "CE_click-save-job-job-application-popup",
            "job-id": cJob.id,
            "job-title": cJob.title,
            save: true,
          });

        return dispatch({
          type: types.BOOKMARK_JOB_SUCCEED,
          payload: { bookmarkJob: cJob },
        });
      }
      if (bookmarkJob === null) {
        return dispatch({
          type: types.BOOKMARK_JOB_FAILED,
          payload: { message: "ERROR" },
        });
      }
    }
    return await dispatch({
      type: types.BOOKMARK_JOB_FAILED,
      payload: { message: "ERROR" },
    });
  };

/*
 * Params:
 * 1) jobId
 * 2) canCancel
 * 3) cancelToken
 */
const unBookmarkJob =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.UNBOOKMARK_JOB });
    const payload = {
      query: `
      mutation {
        unBookmarkJob(input: {jobId: "${params.jobId}"}) {
          success
          job {
            ${jobListAttributes}
          }
        }
      }      
      `,
    };

    let response;

    if (params.useFullUrl) {
      const apiUrl = setCountryApi(params);
      response = await api.apiCall(apiUrl, params, payload);
    } else {
      response = await api.apiCall(
        "/api/job_seeker/v1/graphql",
        params,
        payload
      );
    }

    if (response.status === 200) {
      const { unBookmarkJob } = response.data.data;
      if (unBookmarkJob != null) {
        // Custom event for unsaving/unbookmarking jobs
        const cJob = unBookmarkJob.job;

        let categoryTag;

        if (
          cJob?.boosted === true &&
          cJob?.spotlight === true &&
          cJob?.keywordHighlight === true
        ) {
          categoryTag = "featured_spotlight_keyword";
        } else if (
          cJob?.boosted === true &&
          cJob?.spotlight === true &&
          !cJob?.keywordHighlight
        ) {
          categoryTag = "featured_spotlight";
        } else if (
          cJob?.boosted === true &&
          !cJob?.spotlight &&
          cJob?.keywordHighlight === true
        ) {
          categoryTag = "featured_keyword";
        } else if (
          !cJob?.boosted &&
          cJob?.spotlight === true &&
          cJob?.keywordHighlight === true
        ) {
          categoryTag = "spotlight_keyword";
        } else if (
          !cJob?.boosted &&
          !cJob?.spotlight &&
          cJob?.keywordHighlight === true
        ) {
          categoryTag = "keyword";
        } else if (
          cJob?.boosted === true &&
          !cJob?.spotlight &&
          !cJob?.keywordHighlight
        ) {
          categoryTag = "featured";
        } else if (
          !cJob?.boosted &&
          cJob?.spotlight === true &&
          !cJob?.keywordHighlight
        ) {
          categoryTag = "spotlight";
        }

        !params.appliedDialog &&
          params.origin &&
          sendTrackingEvent({
            event: `${
              cJob.boosted
                ? `CE_unsaved-job-${params.origin}`
                : cJob.secondary_company_id
                ? `${params.origin}-urgent-hiring-job-unsaved`
                : `CE_unsaved-job-${params.origin}`
            }`,
            "company-id": `${cJob.company.id}`,
            "job-id": `${cJob.id}`,
            spotlight: cJob?.spotlight ? true : false,
            featured: cJob?.boosted ? true : false,
            keyword: cJob?.keywordHighlight ? true : false,
          });

        params.appliedDialog === true &&
          sendTrackingEvent({
            event: "CE_click-save-job-job-application-popup",
            "job-id": cJob.id,
            "job-title": cJob.title,
            save: false,
          });

        return dispatch({
          type: types.UNBOOKMARK_JOB_SUCCEED,
          job: unBookmarkJob ? unBookmarkJob.job : null,
          jobId: params.jobId,
        });
      } else {
        return dispatch({
          type: types.UNBOOKMARK_JOB_FAILED,
          payload: { message: "ERROR" },
        });
      }
    }
    return await dispatch({
      type: types.UNBOOKMARK_JOB_FAILED,
      payload: { message: "ERROR" },
    });
  };

/*
 * Params:
 * 1) jobId
 * 2) updateList
 * 3) updateRejectedList
 * 3) canCancel
 * 4) cancelToken
 */
const applyJob =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.APPLY_JOB });

    const deviceType = generateDeviceType();

    const gaSessionId = getGaSessionID();

    const payload = {
      query: `mutation {
      applyJob(input: {
        jobId: "${params.jobId}",
        deviceType: "${deviceType}",
        sessionId: "${gaSessionId}"
        }) {
        applyJob {
          id          
          job {
            ${jobAttributesV1}
          }
        }      
      }
    }
  `,
    };

    let response;

    if (params.useFullUrl) {
      const apiUrl = setCountryApi(params);
      response = await api.apiCall(apiUrl, params, payload);
    } else {
      response = await api.apiCall(
        "/api/job_seeker/v1/graphql",
        params,
        payload
      );
    }

    if (response.status === 200) {
      const { errors } = response.data;
      if (errors) {
        return dispatch({
          type: types.APPLY_JOB_FAILED,
          payload: { message: errors[0].message },
        });
      }

      if (response.data.data.applyJob != null) {
        let cJob;
        let appliedJob = response.data.data.applyJob.applyJob;
        let jobBucketId = appliedJob.id ?? "";
        if (appliedJob && appliedJob.job) {
          cJob = appliedJob.job;
        }

        const origin = params?.origin ?? null;
        const jobDiscovery = params?.jobDiscovery ?? null;

        posthogJobApply(cJob, origin, jobDiscovery);

        return dispatch({
          type: types.APPLY_JOB_SUCCEED,
          payload: {
            jobBucketId: jobBucketId,
            appliedJob: cJob,
            updateList: params.updateList,
            updateRejectedList: params.updateRejectedList,
            updateRecommendedList: params.updateRecommendedList,
            jobApplication: appliedJob,
            triggerNPS: response.data.data.applyJob.triggerNPS, //Not sure whether we need for Web at the moment
          },
        });
      }
    }
    return dispatch({
      type: types.APPLY_JOB_FAILED,
      payload: { message: "Sorry there was a problem with your request" },
    });
  };

const applyJobs =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: types.APPLY_JOBS });

    const deviceType = generateDeviceType();

    const payload = {
      query: `mutation {
        bulkApplyJob(input: {
          jobIds: ${JSON.stringify(params.jobIds)},
          deviceType: "${deviceType}"
        }) {
        applyJobs {                 
          id
          job {
            ${jobAttributesV1}
          }
        }
        triggerNps        
        success
      }
    }
  `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data.data.bulkApplyJob != null) {
            return dispatch({
              type: types.APPLY_JOBS_SUCCEED,
              success: response.data.data.bulkApplyJob.success,
              appliedJobs: response.data.data.bulkApplyJob.applyJobs,
            });
          }
        }
        return dispatch({
          type: types.APPLY_JOBS_FAILED,
          payload: { message: "Sorry there was a problem with your request" },
        });
      })
      .catch(() => {
        return dispatch({
          type: types.APPLY_JOBS_FAILED,
          payload: { message: "Sorry there was a problem with your request" },
        });
      });
  };

const bulkApplyJobs =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: types.BULK_APPLY_JOBS });

    const deviceType = generateDeviceType();

    const payload = {
      query: `mutation {
        bulkApplyJob(input: {
          jobIds: ${JSON.stringify(params.jobIds)},
          deviceType: "${deviceType}"
        }) {
        applyJobs {                 
          id
          job {
            ${jobAttributesV1}
          }
        }
        triggerNps        
        success
      }
    }
  `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data.data.bulkApplyJob != null) {
            return dispatch({
              type: types.BULK_APPLY_JOBS_SUCCEED,
              success: response.data.data.bulkApplyJob.success,
              bulkAppliedJobs: response.data.data.bulkApplyJob.applyJobs,
            });
          }
        }
        return dispatch({
          type: types.BULK_APPLY_JOBS_FAILED,
          payload: { message: "Sorry there was a problem with your request" },
        });
      })
      .catch(() => {
        return dispatch({
          type: types.BULK_APPLY_JOBS_FAILED,
          payload: { message: "Sorry there was a problem with your request" },
        });
      });
  };

/*
 * Params:
 * 1) jobApplicationId
 * 2) canCancel
 * 3) cancelToken
 */
const cancelJobApplication =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: types.CANCELLING_JOB_APPLICATION });

    const apiUrl = setCountryApi(params);

    const payload = {
      query: `mutation {
        unApplyJob(input: {id: "${params.jobApplicationId}"}) {
          unApplyJob {
            id
            job {
              ${jobListAttributes}
              image
              tracks {
                id
                title
              } 
            }
          }
        }
      }
    `,
    };

    return api
      .apiCall(apiUrl, params, payload)
      .then(async (response) => {
        let jobApplicationId = params.jobApplicationId;
        if (response.status === 200 && response.data.data.unApplyJob) {
          const { unApplyJob } = response.data.data.unApplyJob;
          if (unApplyJob != null) {
            return dispatch({
              type: types.CANCEL_JOB_APPLICATION_SUCCEED,
              jobApplication: unApplyJob,
              jobApplicationId: params.jobApplicationId,
            });
          }
          if (unApplyJob === null) {
            return dispatch({
              type: types.CANCEL_JOB_APPLICATION_FAILED,
              payload: {
                message: "ERROR",
                jobApplicationId: jobApplicationId,
              },
            });
          }
        }

        if (response.data.errors && response.data.errors.length > 0) {
          if (
            response.data.errors[0].message &&
            !response.data.errors[0].message.includes(
              "Couldn't find JobBucket with"
            )
          ) {
            jobApplicationId = "-1";
          }
        }

        return dispatch({
          type: types.CANCEL_JOB_APPLICATION_FAILED,
          payload: {
            message: "ERROR",
            jobApplicationId: jobApplicationId,
          },
        });
      })
      .catch(() => {
        return dispatch({
          type: types.CANCEL_JOB_APPLICATION_FAILED,
          payload: {
            message: "ERROR",
            jobApplicationId: params.jobApplicationId,
          },
        });
      });
  };

const updateHoverJob = (hoverJob) => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.UPDATE_HOVER_JOB,
        payload: hoverJob,
      })
    );
  });
};

const updateSelectedJob = (selectedJob) => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.UPDATE_SELECTED_JOB,
        selectedJob: selectedJob,
      })
    );
  });
};

const updateJobId = (jobId) => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.UPDATE_JOB_ID,
        jobId: jobId,
      })
    );
  });
};

const updateJobList = (jobs) => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.UPDATE_JOBS_LIST,
        payload: jobs,
      })
    );
  });
};

const updateJobListFilter = (jobListFilter) => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.UPDATE_JOB_LIST_FILTER,
        jobListFilter: jobListFilter,
      })
    );
  });
};

const updateJobListFilterLanding =
  (jobListFilterLanding) => async (dispatch) => {
    return new Promise((resolve) => {
      return resolve(
        dispatch({
          type: types.UPDATE_JOB_LIST_FILTER_LANDING,
          payload: jobListFilterLanding,
        })
      );
    });
  };

const updateSelectAllJobFilter = (filters) => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.UPDATE_SELECT_ALL_JOB_FILTER,
        filters: filters,
      })
    );
  });
};

// For SEO
/*
 * Params:
 * 1) jobId
 * 2) canCancel
 * 3) cancelToken
 */
const getJobWithoutRedux = (params = {}) => {
  const payload = {
    query: `{
      job(id: "${params.jobId}"){
        ${jobAttributesWithCompanyAndProfileV1}
        crossBorderData
        category
        aggregatedCompanyName
      }
    }
  `,
  };

  return api
    .apiCall("/api/job_seeker/v1/graphql", params, payload, true)
    .then(async (response) => {
      if (response.status === 200) {
        const { job } = response.data.data;
        if (job != null) {
          return job;
        }
        if (job === null) {
          return { jobNotFound: true };
        }
      }
      return { jobNotFound: true };
    });
};

const getTracksWithoutRedux = (params = {}) => {
  const payload = {
    query: `{
        tracks {
          id
          title
          slug
        }
      }      
    `,
  };

  return api
    .apiCall("/api/job_seeker/v1/graphql", params, payload)
    .then(async (response) => {
      if (response.status === 200) {
        const tracks = response.data?.data?.tracks;
        return tracks;
      } else {
        return [];
      }
    })
    .catch((error) => []);
};

const getStateRegionWithoutRedux = async (params = {}) => {
  const payload = {
    query: `
      {
        stateRegions {
          id
          state
          slug
        }
      }      
      `,
  };

  return api
    .apiCall("/api/job_seeker/v1/graphql", params, payload)
    .then(async (response) => {
      if (response.status === 200) {
        const stateRegions = response.data.data.stateRegions;
        return stateRegions;
      }
    });
};

const getJobTypesWithoutRedux = (params = {}) => {
  const payload = {
    query: `{
      jobTypes {
          id
          title
        }
      }      
    `,
  };

  return api
    .apiCall("/api/job_seeker/v1/graphql", params, payload)
    .then(async (response) => {
      if (response.status === 200) {
        const jobTypes = response.data.data.jobTypes;
        return jobTypes;
      }
    });
};

const getExperienceLevelsWithoutRedux = (params = {}) => {
  const payload = {
    query: `{
      experiences {
          id
          title
        }
      }      
    `,
  };

  return api
    .apiCall("/api/job_seeker/v1/graphql", params, payload)
    .then(async (response) => {
      if (response.status === 200) {
        const experienceLevels = response.data.data.experiences;
        return experienceLevels;
      }
    });
};

const getJobTrackSlugWithoutRedux = (params = {}) => {
  const payload = {
    query: `{
      tracks {
        id
        slug
      }
    }      
  `,
  };

  return api
    .apiCall("/api/job_seeker/v1/graphql", params, payload, true)
    .then(async (response) => {
      if (response.status === 200) {
        const job_track_slug = response.data.data.tracks;
        if (job_track_slug != null) {
          return job_track_slug;
        }
        if (job_track_slug === null) {
          return null;
        }
      }
      return null;
    });
};

const getJobAppliedAndBookmarkStatus =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.GET_JOB_BOOKMARK_AND_APPLY_STATUS });

    const payload = {
      query: `{
      job(id: "${params.jobId}"){
        bookmark
        haveApplied
      }
    }
  `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data?.data?.job) {
            const { bookmark, haveApplied } = response.data.data.job;

            dispatch({ type: types.GET_JOB_BOOKMARK_AND_APPLY_STATUS_SUCCEED });
            return { bookmark, haveApplied };
          }
        }

        dispatch({ type: types.GET_JOB_BOOKMARK_AND_APPLY_STATUS_FAILED });
        return null;
      });
  };

const updateBookmarkedRecommendedJobs =
  (recommendedJobs) => async (dispatch) => {
    return dispatch({
      type: types.UPDATE_RECOMMENDED_JOBS,
      bookmarkedRecommendedJobs: recommendedJobs,
    });
  };

const getRecommendedJobs =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.FETCHING_ONBOARDING_RECOMMENDED_JOBS });

    const payload = {
      query: `{
        recommendedJobs {
          totalCount
          pageInfo {
            startCursor
            hasNextPage
            hasPreviousPage
            endCursor
          }
          edges {
            node {
              ${jobAttributesV1}
            }
          }
        }
      }`,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data?.data?.recommendedJobs) {
            const jobs = response.data.data.recommendedJobs;
            return dispatch({
              type: types.FETCH_ONBOARDING_RECOMMENDED_JOBS_SUCCEED,
              jobs: jobs?.edges ? jobs.edges : [],
            });
          }
          return dispatch({
            type: types.FETCH_ONBOARDING_RECOMMENDED_JOBS_FAILED,
          });
        } else {
          return dispatch({
            type: types.FETCH_ONBOARDING_RECOMMENDED_JOBS_FAILED,
          });
        }
      });
  };

const getPostApplyRecommendedJobs =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.FETCHING_RECOMMENDED_JOBS });

    const payload = {
      query: `{
        ashleySimilarRecommendedJobs(version: "v1.2", globalId: "${params.globalId}", first: ${params.first}) {
          maxPageSize
          totalCount
          totalPage
          nodes {
            ${jobListAttributes}
          }
        }
      }`,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data?.data?.ashleySimilarRecommendedJobs) {
            const jobs = response.data.data.ashleySimilarRecommendedJobs.nodes;
            const filteredJobs = jobs.filter((job) => job !== null);

            return dispatch({
              type: types.FETCH_RECOMMENDED_JOBS_SUCCEED,
              jobs: filteredJobs,
            });
          }
          return dispatch({
            type: types.FETCH_RECOMMENDED_JOBS_FAILED,
          });
        } else {
          return dispatch({
            type: types.FETCH_RECOMMENDED_JOBS_FAILED,
          });
        }
      });
  };

const getPostApplyRecommendedJobsWithoutRedux = (params = {}) => {
  const payload = {
    query: `{
      ashleySimilarRecommendedJobs(version: "v1.2", globalId: "${params.globalId}", first: ${params.first}) {
        maxPageSize
        totalCount
        totalPage
        nodes {
          ${jobListAttributes}
        }
      }
    }`,
  };

  return api
    .apiCall("/api/job_seeker/v1/graphql", params, payload)
    .then(async (response) => {
      if (response.status === 200) {
        if (response.data?.data?.ashleySimilarRecommendedJobs) {
          const jobs = response.data.data.ashleySimilarRecommendedJobs;
          const filteredJobs = jobs.nodes.filter((job) => job !== null);
          return filteredJobs;
        }
      }
      return [];
    });
};

const getPostApplyRecommendedJobsBoost =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.FETCHING_RECOMMENDED_JOBS });

    const payload = {
      query: `{
        AshleySimilarRecommendedJobsWithBoost(version: "v1.2", globalId: "${params.globalId}") {
          maxPageSize
          totalCount
          totalPage
          nodes {
            ${jobListAttributes}
          }
        }
      }`,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data?.data?.AshleySimilarRecommendedJobsWithBoost) {
            const jobs =
              response.data.data.AshleySimilarRecommendedJobsWithBoost.nodes;
            return dispatch({
              type: types.FETCH_RECOMMENDED_JOBS_SUCCEED,
              jobs: jobs,
            });
          }
          return dispatch({
            type: types.FETCH_RECOMMENDED_JOBS_FAILED,
          });
        } else {
          return dispatch({
            type: types.FETCH_RECOMMENDED_JOBS_FAILED,
          });
        }
      });
  };

const getTrendingJobKeywords =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.FETCHING_HOMEPAGE_TRENDING_KEYWORDS });

    let limitInput = `limit: ${params.limit ? params.limit : 10}`;

    const payload = {
      query: `{
        trendingKeywords(${limitInput}) {
          keywords
        }
      }`,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data?.data?.trendingKeywords?.keywords) {
            const keywords = response.data.data.trendingKeywords.keywords;
            return dispatch({
              type: types.FETCH_HOMEPAGE_TRENDING_KEYWORDS_SUCCEED,
              payload: keywords ? keywords : [],
            });
          }
          return dispatch({
            type: types.FETCH_HOMEPAGE_TRENDING_KEYWORDS_FAILED,
          });
        } else {
          return dispatch({
            type: types.FETCH_HOMEPAGE_TRENDING_KEYWORDS_FAILED,
          });
        }
      });
  };

const updateJobAlertToggle = (status, syncToggle) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_JOB_ALERT_TOGGLE,
    status: status,
    syncToggle: syncToggle,
  });
};

const getJobAlerts =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.FETCHING_JOB_ALERTS });

    const payload = {
      query: `{
        jobAlerts {
          totalNewJobsCount
          jobAlerts {
            newJobsCount
            jobAlert { 
            ${v1JobAlertAttributes}
            }
          }
        }
      }`,
    };

    let response;

    if (params.useFullUrl) {
      const apiUrl = setCountryApi(params);

      response = await api.apiCall(apiUrl, params, payload);
    } else {
      response = await api.apiCall(
        "/api/job_seeker/v1/graphql",
        params,
        payload
      );
    }

    if (response.status === 200) {
      if (response.data?.data?.jobAlerts?.jobAlerts) {
        const jobAlerts = response.data?.data?.jobAlerts?.jobAlerts.map(
          (item) => item.jobAlert
        );

        return dispatch({
          type: types.FETCH_JOB_ALERTS_SUCCEED,
          jobAlerts: jobAlerts ? jobAlerts : [],
        });
      }
      return dispatch({
        type: types.FETCH_JOB_ALERTS_FAILED,
      });
    } else {
      return dispatch({
        type: types.FETCH_JOB_ALERTS_FAILED,
      });
    }
  };

const getJobAlert =
  (params = {}) =>
  async (dispatch) => {
    const apiUrl = setCountryApi(params);

    dispatch({ type: types.GET_JOB_ALERT });

    const payload = {
      query: `{
      jobAlert(id: "${params.id}"){
        ${v1JobAlertAttributes}
      }
    }
  `,
    };

    return api.apiCall(apiUrl, params, payload).then(async (response) => {
      if (response.status === 200) {
        if (response.data?.data?.jobAlert) {
          const jobAlert = response.data.data.jobAlert;
          return dispatch({
            type: types.GET_JOB_ALERT_SUCCEED,
            jobAlert: jobAlert,
          });
        }
        return dispatch({
          type: types.GET_JOB_ALERT_FAILED,
        });
      } else {
        return dispatch({
          type: types.GET_JOB_ALERT_FAILED,
        });
      }
    });
  };

const createJobAlert =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.CREATING_JOB_ALERT });
    const payload = {
      query: `
      mutation {
        addJobAlert(input: {title: "${params.title}", frequency: "${params.frequency}", medium: "${params.medium}", yearOfExperience: [${params.yearOfExperience}], salary: ${params.salary}}) {
          success
          errors {
            message
          }
          jobAlerts {
            newJobsCount
            jobAlert {
            ${v1JobAlertAttributes}
            }
          }
        }
      }
      `,
    };

    let response;

    if (params.useFullUrl) {
      const apiUrl = setCountryApi(params);

      response = await api.apiCall(apiUrl, params, payload);
    } else {
      response = await api.apiCall(
        "/api/job_seeker/v1/graphql",
        params,
        payload
      );
    }

    if (response.status === 200) {
      if (response.data?.data?.addJobAlert?.success) {
        let matchingJobAlert;

        const jobAlerts = response.data?.data?.addJobAlert?.jobAlerts.map(
          (item) => item.jobAlert
        );

        if (jobAlerts && Array.isArray(jobAlerts) && jobAlerts.length > 0) {
          const searchMatch = jobAlerts.filter(
            (alert) => alert.title === params.title
          );

          if (searchMatch.length > 0) {
            matchingJobAlert = searchMatch[0];
          }
        }

        return dispatch({
          type: types.CREATE_JOB_ALERT_SUCCEED,
          jobAlerts: jobAlerts ? jobAlerts : [],
          jobAlert: matchingJobAlert ?? {},
        });
      } else {
        const errors = response.data?.data?.addJobAlert?.errors;
        if (
          errors[0].message &&
          errors[0].message.includes(
            "you have reach maximum number of alerts per user!"
          )
        ) {
          return dispatch({
            type: types.REACHED_MAX_JOB_ALERTS,
          });
        }

        if (
          errors[0].message &&
          errors[0].message.includes("Keywords has already been added.")
        ) {
          return dispatch({
            type: types.JOB_ALERT_EXISTS,
          });
        }

        return dispatch({
          type: types.CREATE_JOB_ALERT_FAILED,
        });
      }
    }
    return await dispatch({
      type: types.CREATE_JOB_ALERT_FAILED,
    });
  };

const deleteJobAlert =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.DELETING_JOB_ALERT });
    const payload = {
      query: `mutation {
        deleteJobAlert(input: {id: "${params.id}"}) {
          success
          errors {
            message
          }
          jobAlerts {
            ${v1JobAlertAttributes}
          }
        }
      }`,
    };

    let response;

    if (params.useFullUrl) {
      const apiUrl = setCountryApi(params);
      response = await api.apiCall(apiUrl, params, payload);
    } else {
      response = await api.apiCall(
        "/api/job_seeker/v1/graphql",
        params,
        payload
      );
    }

    if (response.status === 200) {
      if (response.data?.data?.deleteJobAlert) {
        const jobAlerts = response.data?.data?.deleteJobAlert?.jobAlerts;
        return dispatch({
          type: types.DELETE_JOB_ALERT_SUCCEED,
          jobAlerts: jobAlerts ? jobAlerts : [],
        });
      }
      return dispatch({
        type: types.DELETE_JOB_ALERT_FAILED,
      });
    } else {
      return dispatch({
        type: types.DELETE_JOB_ALERT_FAILED,
      });
    }
  };

const replaceJobAlert =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: types.REPLACING_JOB_ALERT });

    let queryInput = `job_alert_id: "${params.id}", title: "${params.title}"`;

    const payload = {
      query: `mutation {
        replaceJobAlert(input: {${queryInput}}) {
          success
          errors {
            message
          }
          job_alerts {
            ${jobAlertAttributes}
          }
        }
      }`,
    };

    return api
      .apiCall("/api/v2/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data?.data?.replaceJobAlert) {
            const jobAlerts = response.data.data.replaceJobAlert.job_alerts;
            return dispatch({
              type: types.REPLACE_JOB_ALERT_SUCCEED,
              jobAlerts: jobAlerts ? jobAlerts : [],
            });
          }
          return dispatch({
            type: types.REPLACE_JOB_ALERT_FAILED,
          });
        } else {
          return dispatch({
            type: types.REPLACE_JOB_ALERT_FAILED,
          });
        }
      });
  };

const updateJobAlert =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.UPDATING_JOB_ALERT });

    const currentDate = dayjs().format("YYYY-MM-DD");
    let keywordInput = `title: "${params.keyword}"`;
    let frequencyInput = `frequency: "${params.frequency}",`;
    let mediumInput = `medium: "${params.medium}",`;
    let yearOfExperience = `yearOfExperience: [${params.yearOfExperience}],`;
    let salary = `salary: ${params.salary},`;
    let activate = `activate : ${params.activate},`;
    let lastAccessAtInput = `lastAccessAt: "${currentDate}",`;
    let queryInput =
      `id: ${params.id},` +
      keywordInput +
      frequencyInput +
      mediumInput +
      yearOfExperience +
      salary +
      activate +
      lastAccessAtInput;

    // Remove trailing ","
    if (queryInput.charAt(queryInput.length - 1) == ",") {
      queryInput = queryInput.substring(0, queryInput.length - 1);
    }

    const payload = {
      query: `mutation {
        updateJobAlert(input: {${queryInput}}) {
          success
          errors {
            message
          }
          jobAlert {
            newJobsCount
            jobAlert {
              ${v1JobAlertAttributes}
            }
          }
        }
      }
      `,
    };

    let response;

    if (params.useFullUrl) {
      const apiUrl = setCountryApi(params);
      response = await api.apiCall(apiUrl, params, payload);
    } else {
      response = await api.apiCall(
        "/api/job_seeker/v1/graphql",
        params,
        payload
      );
    }

    if (response.status === 200) {
      if (response.data?.data?.updateJobAlert) {
        const jobAlert = response.data.data.updateJobAlert.jobAlert.jobAlert;
        return dispatch({
          type: types.UPDATE_JOB_ALERT_SUCCEED,
          jobAlert: jobAlert,
        });
      }
      return dispatch({
        type: types.UPDATE_JOB_ALERT_FAILED,
      });
    } else {
      return dispatch({
        type: types.UPDATE_JOB_ALERT_FAILED,
      });
    }
  };

const visitorAddJobAlert =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.VISITOR_ADDING_JOB_ALERT }); //Before modification: Email and title only
    let queryInput = `email: "${params.email}", title: "${params.title}", yearOfExperience: [${params.yearOfExperience}], salary: ${params.salary}`;

    const payload = {
      query: `mutation {
        visitorAddJobAlert(input: {${queryInput}}) {
          success
          errors {
            message
          }
          maxJobAlerts
          jobAlert {
            ${v1JobAlertAttributes}
          }
        }
      }
    `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data?.data?.visitorAddJobAlert?.success) {
            if (params.origin) {
              // Custom event for visitor job alert creation on job alert landing page
              sendTrackingEvent({
                event: "CE_create-job-alert-job-alert-landing-page",
                "job-alert-id":
                  response.data?.data?.visitorAddJobAlert?.jobAlert?.id,
                "job-alert-frequency":
                  response.data?.data?.visitorAddJobAlert?.jobAlert?.frequency,
                "job-alert-medium":
                  response.data?.data?.visitorAddJobAlert?.jobAlert?.medium,
              });
            }

            return dispatch({
              type: types.VISITOR_ADD_JOB_ALERT_SUCCEED,
              jobAlert: response.data?.data?.visitorAddJobAlert?.jobAlert,
            });
          }

          if (response.data?.data?.visitorAddJobAlert?.maxJobAlerts) {
            return dispatch({
              type: types.VISITOR_ADD_JOB_ALERT_FAILED,
              error: "You have reach maximum number of alerts per visitor",
            });
          }

          if (
            response.data?.data?.visitorAddJobAlert?.errors &&
            response.data?.data?.visitorAddJobAlert?.errors?.length > 0
          ) {
            // If user account already exists or email exists
            if (response.data?.data?.visitorAddJobAlert?.errors[0]?.message) {
              return dispatch({
                type: types.VISITOR_ADD_JOB_ALERT_FAILED,
                error:
                  response.data?.data?.visitorAddJobAlert?.errors[0]?.message,
              });
            }
          }

          if (
            response.data?.errors &&
            Array.isArray(response.data?.errors) &&
            response.data?.errors?.length > 0
          ) {
            return dispatch({
              type: types.VISITOR_ADD_JOB_ALERT_FAILED,
              error:
                response.data?.errors[0]?.message ??
                "Oops! Something went wrong. Please try again later",
            });
          }
        } else {
          return dispatch({
            type: types.VISITOR_ADD_JOB_ALERT_FAILED,
            error: "Oops! Something went wrong. Please try again later",
          });
        }
      });
  };

const visitorUnsubscribeJobAlert =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: types.VISITOR_UNSUBSCRIBING_JOB_ALERT });

    let queryInput = `id: ${params.id}, reason: "${params.feedback}"`;

    const payload = {
      query: `mutation {
        visitorUnsubscribeJobAlert(input: {${queryInput}}) {
          success
          errors {
              message
          }
        }
      }`,
    };

    return api
      .apiCall("/api/v2/graphql", params, payload)
      .then(async (response) => {
        if (response.status == 200) {
          if (response.data?.data?.visitorUnsubscribeJobAlert?.success) {
            return dispatch({
              type: types.VISITOR_UNSUBSCRIBE_JOB_ALERT_SUCCEED,
            });
          }
          return dispatch({
            type: types.VISITOR_UNSUBSCRIBE_JOB_ALERT_FAILED,
          });
        }
        return dispatch({
          type: types.VISITOR_UNSUBSCRIBE_JOB_ALERT_FAILED,
        });
      });
  };

const clearJobAlerts = () => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.CLEAR_JOB_ALERTS,
      })
    );
  });
};

const fetchSearchSuggestions =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.FETCHING_SEARCH_DATA });
    const payload = {
      query: `{
        autoSuggestTitles(type: "job", keyword: "${params.keyword}") {
          data
          suggestions
        }
      }      
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data?.data?.autoSuggestTitles) {
            const suggestedSearch = response.data.data.autoSuggestTitles;
            if (suggestedSearch) {
              return dispatch({
                type: types.FETCH_SEARCH_DATA_SUCCEED,
                payload: {
                  searchData: suggestedSearch.data ? suggestedSearch.data : [],
                  searchSuggestions: suggestedSearch.suggestions
                    ? suggestedSearch.suggestions
                    : [],
                },
              });
            }
          }
          return dispatch({
            type: types.FETCH_SEARCH_DATA_FAILED,
          });
        }
        return dispatch({
          type: types.FETCH_SEARCH_DATA_FAILED,
        });
      });
  };

const updateSearchType = (params) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_SEARCH_TYPE,
    payload: params,
  });
};

const clearSearchSuggestions = () => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.CLEAR_SEARCH_SUGGESTIONS,
      })
    );
  });
};

const unSaveJob = (isSignedIn, jobId, origin) => async (dispatch) => {
  return new Promise((resolve) => {
    if (!isSignedIn) return;

    if (jobId) {
      store.getState().jobs.currentId = jobId;

      let params = {
        jobId: jobId,
        canCancel: false,
        origin: origin ? "recommended-jobs" : "individual-job",
      };

      dispatch(unBookmarkJob(params));
    }

    return resolve(
      dispatch({
        type: types.UNSAVE_JOB,
      })
    );
  });
};

const saveJob = (isSignedIn, jobId, origin) => async (dispatch) => {
  return new Promise((resolve) => {
    if (!isSignedIn) {
      dispatch(updateSignInText("Log In to Save Jobs"));
      dispatch(updateSignUpText("Sign Up to Save Jobs"));
      return;
    }

    if (jobId) {
      store.getState().jobs.currentId = jobId;

      let params = {
        jobId: jobId,
        canCancel: false,
        origin: origin ? "recommended-jobs" : "individual-job",
      };

      dispatch(bookmarkJob(params));
    }

    return resolve(
      dispatch({
        type: types.SAVE_JOB,
      })
    );
  });
};

const updateAshleyInfoDialog = (params) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_ASHLEY_TOGGLE_DIALOG,
    payload: params,
  });
};

const getAshleyRecommendedJobs =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.FETCHING_ASHLEY_RECOMMENDED_JOBS });
    /**
     * ASHLEY RECOMMENDATION BEHAVIOUR
     *
     * Scenario 1 - going to NEXT page
     * {last: null, first: null, before: "null", after: "endCursor" }
     *
     * Scenario 2 - going to PREVIOUS page
     * {last: 30, first: null, before: "startCursor", after: "" }
     *
     * NOTE: after needs to be an empty string when going to previous page for it to work properly
     */

    const localEndCursor = params.endCursor ?? "";
    const localStartCursor = params.startCursor ?? "";

    const payload = {
      query: `{
        ashleyRecommendedJobs(last: ${params.last}, first: ${params.first} before: "${localStartCursor}", after: "${localEndCursor}", version:"v1.2") {
          totalCount
          totalPage
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          nodes {
            ${jobListAttributes}
          }
        }     
      }
    `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { errors } = response.data;

          const errorObj = Array.isArray(errors) ? errors[0] : errors;

          const { error_code } = errorObj?.extensions || {};

          if (
            (errors !== undefined &&
              errors !== null &&
              Array.isArray(errors) &&
              errors?.length > 0) ||
            (error_code !== undefined && error_code !== null)
          ) {
            return dispatch({
              type: types.FETCH_ASHLEY_RECOMMENDED_JOBS_FAILED,
              errorMessage: Array.isArray(errors)
                ? errors[0]?.message
                : "Error",
              currentLoadingJobsKey: params.currentLoadingJobsKey,
              ashleyRecommendsErrorCode: error_code ?? "",
            });
          }

          const pageInfo = response.data.data.ashleyRecommendedJobs.pageInfo;

          const jobList = response.data.data.ashleyRecommendedJobs.nodes;
          const filteredJobs = jobList.filter((job) => job !== null);
          const totalPages = response.data.data.ashleyRecommendedJobs.totalPage;
          const totalJobsCount =
            response.data.data.ashleyRecommendedJobs.totalCount;

          return dispatch({
            type: types.FETCH_ASHLEY_RECOMMENDED_JOBS_SUCCEED,
            payload: {
              filteredJobs,
              refresh: params.refresh,
              currentLoadingJobsKey: params.currentLoadingJobsKey,
              totalJobsPages: totalPages,
              totalJobsCount: totalJobsCount,
              ashleyRecommendsError: false,
              pageInfo: pageInfo,
            },
          });
        } else {
          return dispatch({
            type: types.FETCH_ASHLEY_RECOMMENDED_JOBS_FAILED,
            currentLoadingJobsKey: params.currentLoadingJobsKey,
          });
        }
      })
      .catch(() => {
        return dispatch({
          type: types.FETCH_ASHLEY_RECOMMENDED_JOBS_FAILED,
          currentLoadingJobsKey: params.currentLoadingJobsKey,
        });
      });
  };

const getAshleyAwsRecommendedJobs =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.FETCHING_ASHLEY_AWS_RECOMMENDED_JOBS });
    /**
     * ASHLEY RECOMMENDATION BEHAVIOUR
     *
     * Scenario 1 - going to NEXT page
     * {last: null, first: null, before: "null", after: "endCursor" }
     *
     * Scenario 2 - going to PREVIOUS page
     * {last: 30, first: null, before: "startCursor", after: "" }
     *
     * NOTE: after needs to be an empty string when going to previous page for it to work properly
     */

    const localEndCursor = params.endCursor ?? "";
    const localStartCursor = params.startCursor ?? "";

    const payload = {
      query: `{
        ashleyAwsPersonalizeRecommendedJobs(last: ${params.last}, first: ${params.first} before: "${localStartCursor}", after: "${localEndCursor}") {
        totalCount
        totalPage
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        nodes {
          ${jobListAttributes}
        }
      }     
    }
  `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { errors } = response.data;

          const errorObj = Array.isArray(errors) ? errors[0] : errors;

          const { error_code } = errorObj?.extensions || {};

          if (
            (errors !== undefined &&
              errors !== null &&
              Array.isArray(errors) &&
              errors?.length > 0) ||
            (error_code !== undefined && error_code !== null)
          ) {
            return dispatch({
              type: types.FETCH_ASHLEY_RECOMMENDED_JOBS_FAILED,
              errorMessage: Array.isArray(errors)
                ? errors[0]?.message
                : "Error",
              currentLoadingJobsKey: params.currentLoadingJobsKey,
              ashleyRecommendsErrorCode: error_code ?? "",
            });
          }

          const pageInfo =
            response.data.data.ashleyAwsPersonalizeRecommendedJobs.pageInfo;

          const jobList =
            response.data.data.ashleyAwsPersonalizeRecommendedJobs.nodes;
          const totalPages =
            response.data.data.ashleyAwsPersonalizeRecommendedJobs.totalPage;
          const totalJobsCount =
            response.data.data.ashleyAwsPersonalizeRecommendedJobs.totalCount;

          return dispatch({
            type: types.FETCH_ASHLEY_AWS_RECOMMENDED_JOBS_SUCCEED,
            payload: {
              jobList,
              refresh: params.refresh,
              currentLoadingJobsKey: params.currentLoadingJobsKey,
              totalJobsPages: totalPages,
              totalJobsCount: totalJobsCount,
              ashleyRecommendsError: false,
              pageInfo: pageInfo,
            },
          });
        } else {
          return dispatch({
            type: types.FETCH_ASHLEY_AWS_RECOMMENDED_JOBS_FAILED,
            currentLoadingJobsKey: params.currentLoadingJobsKey,
          });
        }
      })
      .catch(() => {
        return dispatch({
          type: types.FETCH_ASHLEY_AWS_RECOMMENDED_JOBS_FAILED,
          currentLoadingJobsKey: params.currentLoadingJobsKey,
        });
      });
  };

const closeAshleyErrorDialog = () => async (dispatch) => {
  return dispatch({
    type: types.CLOSE_ASHLEY_ERROR_DIALOG,
  });
};

const getHomepageLogo =
  (params = {}) =>
  async (dispatch) => {
    const payload = {
      query: `{
        homepageFeaturedCompanyLogo {
          totalCount
          pageInfo {
            startCursor
            hasNextPage
            hasPreviousPage
            endCursor
          }
          edges {
            node {
              companyId
              companyName
              company{
                slug
              }
              logo
              order
            }
          }
        }
      }  
    `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { edges } = response?.data?.data?.homepageFeaturedCompanyLogo;
          return dispatch({
            type: types.FETCH_HOMEPAGE_LOGO,
            payload: edges,
          });
        }
      });
  };

const getTrendingChip =
  (params = {}) =>
  async (dispatch, getState) => {
    let limitInput = `first: ${params.first ? params.first : 10}`;

    try {
      const payload = {
        query: `{
          homepageTrendingSearches(${limitInput}) {
            nodes{
              id
              order
              title
            }
          }
        }`,
      };

      const response = await api.apiCall(
        "/api/job_seeker/v1/graphql",
        params,
        payload
      );

      if (response.status === 200) {
        const { nodes } = response?.data?.data?.homepageTrendingSearches || [];

        if (nodes) {
          return dispatch({
            type: types.FETCH_TRENDING_CHIP,
            payload: nodes,
          });
        }
      }
    } catch (error) {
      // Error handling
    }
  };

const getHomepageAds =
  (params = {}) =>
  async (dispatch) => {
    const payload = {
      query: `{
        homepageSliders {
          totalCount
          pageInfo {
            startCursor
            hasNextPage
            hasPreviousPage
            endCursor
          }
          edges {
            node {
              title
              description
              chipText
              image
              urlLink
              order
            }
          }
        }
      }  
    `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { edges } = response.data.data.homepageSliders;
          return dispatch({
            type: types.FETCH_HOMEPAGE_ADS,
            payload: edges,
          });
        }
      });
  };

const updateJobAlertEmail = (params) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_JOB_ALERT_EMAIL,
    payload: params,
  });
};

const updateShowJobAlertDialog = (params) => async (dispatch) => {
  return dispatch({
    type: types.SHOW_VISITOR_JOB_ALERT_DIALOG,
    payload: params,
  });
};
const updateShowJobAlertSuccessDialog = (params) => async (dispatch) => {
  return dispatch({
    type: types.SHOW_VISITOR_JOB_ALERT_SUCCESS,
    payload: params,
  });
};

const updateForYouPaginationCounter = (params) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_FOR_YOU_PAGINATION_COUNT,
    counter: params,
  });
};

const updateSelectedJobId = (params) => async (dispatch) => {
  return dispatch({
    type: types.SELECTED_JOB_ID,
    payload: params,
  });
};

const updateFetchingForYou = (status) => async (dispatch) => {
  return dispatch({
    type: types.FETCHING_FOR_YOU,
    status: status,
  });
};

const getRecentlyViewedJobs =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.FETCHING_RECENT_VIEWED_JOBS });

    const localEndCursor = params.endCursor ?? "";
    const localStartCursor = params.startCursor ?? "";

    const payload = {
      query: `{
        recentlyViewedJobs(last: ${params.last}, first: ${params.first}, before: "${localStartCursor}", after: "${localEndCursor}") {
          totalCount
          pageInfo {
            startCursor
            hasNextPage
            hasPreviousPage
            endCursor
          }
          nodes {
            ${jobListAttributes}
          }
        }
      }
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { errors } = response.data;

          if (Array.isArray(errors) && errors.length > 0) {
            return dispatch({
              type: types.FETCH_RECENT_VIEWED_JOBS_FAILED,
              currentLoadingJobsKey: params.currentLoadingJobsKey,
            });
          }

          const pageInfo = response.data?.data?.recentlyViewedJobs?.pageInfo;
          const jobList = response.data?.data?.recentlyViewedJobs?.nodes;

          return dispatch({
            type: types.FETCH_RECENT_VIEWED_JOBS_SUCCEED,
            payload: {
              jobList: jobList,
              pageInfo: pageInfo,
              historyExist: jobList?.length > 0,
            },
          });
        } else {
          return dispatch({
            type: types.FETCH_RECENT_VIEWED_JOBS_FAILED,
            currentLoadingJobsKey: params.currentLoadingJobsKey,
          });
        }
      })
      .catch(() => {
        return dispatch({
          type: types.FETCH_RECENT_VIEWED_JOBS_FAILED,
          currentLoadingJobsKey: params.currentLoadingJobsKey,
        });
      });
  };

const postGaSessionId =
  (params = {}) =>
  async () => {
    const payload = {
      query: `{
        job(id: "${params.jobId}", sessionId: "${params.sessionId}"){
          id
        }
      }
      `,
    };

    const result = api.apiCall("/api/job_seeker/v1/graphql", params, payload);

    if (result.status === 200 && result?.data?.data?.job?.id) return true;

    return false;
  };

const getJobSimilarJobsV1 =
  (params = {}) =>
  async (dispatch) => {
    const payload = {
      query: `{
          similarJobsUnderJobPage(jobId: "${params.jobId}", first: 6) {
            nodes {
              ${jobListAttributes}
            }
          }
        }
      `,
    };

    try {
      const result = await api.apiCall(
        "/api/job_seeker/v1/graphql",
        params,
        payload
      );

      if (
        Array.isArray(result.data?.errors) &&
        result.data?.errors?.length > 0
      ) {
        return dispatch({
          type: types.FETCH_SIMILAR_JOBS_UNDER_JOB_PAGE_FAILED,
        });
      }

      if (result.status === 200) {
        const { nodes } = result?.data?.data?.similarJobsUnderJobPage || {};

        return dispatch({
          type: types.FETCH_SIMILAR_JOBS_UNDER_JOB_PAGE_SUCCEED,
          payload: nodes,
        });
      }

      return dispatch({
        type: types.FETCH_SIMILAR_JOBS_UNDER_JOB_PAGE_FAILED,
      });
    } catch (error) {
      return dispatch({
        type: types.FETCH_SIMILAR_JOBS_UNDER_JOB_PAGE_FAILED,
      });
    }
  };

const bookmarkSingleJobCompany =
  (params = {}) =>
  async (dispatch) => {
    const payload = {
      query: `
      mutation {
        bookmarkCompany(input: {companyId: "${params.companyId}"}) {
          bookmarkCompany {
            company {
              bookmark
            }
          }
        }
      }    
    `,
    };

    const response = await api.apiCall(
      "/api/job_seeker/v1/graphql",
      params,
      payload
    );

    if (response.status !== 200)
      return dispatch({ type: types.FOLLOW_SINGLE_JOB_COMPANY_FAILED });

    const { bookmark } =
      response?.data?.data?.bookmarkCompany?.bookmarkCompany?.company || {};

    if (bookmark !== null && bookmark !== undefined) {
      return dispatch({
        type: types.FOLLOW_SINGLE_JOB_COMPANY_SUCCEED,
        payload: {
          newBookmarkStatus: bookmark,
        },
      });
    } else {
      const { errors } = response.data || {};

      return dispatch({
        type: types.FOLLOW_SINGLE_JOB_COMPANY_FAILED,
        message: Array.isArray(errors) ? errors[0].toString() : null,
      });
    }
  };

const unBookmarkSingleJobCompany =
  (params = {}) =>
  async (dispatch) => {
    const payload = {
      query: `
      mutation {
        unBookmarkCompany(input: {companyId: "${params.companyId}"}) {
          company {
            bookmark
          }          
        }
      }       
    `,
    };

    const response = await api.apiCall(
      "/api/job_seeker/v1/graphql",
      params,
      payload
    );

    if (response.status !== 200)
      return dispatch({ type: types.FOLLOW_SINGLE_JOB_COMPANY_FAILED });

    const { bookmark } = response?.data?.data?.unBookmarkCompany?.company || {};

    if (bookmark !== null && bookmark !== undefined) {
      return dispatch({
        type: types.FOLLOW_SINGLE_JOB_COMPANY_SUCCEED,
        payload: {
          newBookmarkStatus: bookmark,
        },
      });
    } else {
      const { errors } = response.data || {};

      return dispatch({
        type: types.FOLLOW_SINGLE_JOB_COMPANY_FAILED,
        message: Array.isArray(errors) ? errors[0].toString() : null,
      });
    }
  };

// To manage predefined job filter flow
const updateCheckingUrlParamForJobFilterStatus =
  (status) => async (dispatch) => {
    return new Promise((resolve) => {
      return resolve(
        dispatch({
          type: types.UPDATE_CHECKING_URL_PARAM_FOR_JOB_FILTER_STATUS,
          status: status,
        })
      );
    });
  };

const getJobsMicrosite =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.FETCHING_JOBS });

    const stateRegionInput = params?.stateRegionList
      ? `stateRegionList:[${params?.stateRegionList.map(
          (item) => `"${item}"`
        )}]`
      : `stateRegionList:null`;

    const trackListInput = params?.trackIdList
      ? `trackIdList:[${params?.trackIdList}]`
      : `trackIdList:null`;

    const payload = {
      query: `{
        micrositeForJobs(companyName: "${params?.companyName}", first:${params?.first}, last:${params?.last}, after:"${params?.after}", before:"${params?.before}", ${stateRegionInput}, ${trackListInput}, companyIndustriesIdList:${params?.companyIndustriesIdList}, keyword:"${params?.keyword}",) {
          nodes {
            title
            company {
              id
              name
              logo
              isMagic
              isMdec
            }
            jobType
            location
            stateRegion
            imageThumb
            tracks {
              title
            }
            slug
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }    
    `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          if (response?.data?.data?.micrositeForJobs) {
            const jobList = response?.data?.data?.micrositeForJobs?.nodes;
            const pageInfo = response.data?.data?.micrositeForJobs?.pageInfo;

            return dispatch({
              type: types.FETCH_MICROSITE_JOBS_SUCCEED,
              payload: {
                jobList: jobList,
                pageInfo: pageInfo,
              },
            });
          }
        }
        return dispatch({
          type: types.FETCH_MICROSITE_JOBS_FAILED,
        });
      });
  };

const updateJobListSearchKeyword = (params) => async (dispatch) => {
  dispatch(
    updateSearchFeedback({
      showCard: true,
      feedbackSubmit: false,
    })
  );
  return dispatch({
    type: types.UPDATE_JOB_SEARCH_KEYWORD,
    payload: params.keyword,
  });
};

const updateJobSearchKeyword = (params) => async (dispatch) => {
  dispatch(
    updateSearchFeedback({
      showCard: true,
      feedbackSubmit: false,
    })
  );
  return dispatch({
    type: types.UPDATE_JOB_SEARCH_OPTION_KEYWORD,
    payload: params.keyword,
  });
};

const updateSearchFeedback = (params) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_SEARCH_FEEDBACK,
    payload: params,
  });
};

const getJobsCampaign =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.FETCH_CAMPAIGN_JOBS });

    const firstParams = params.first ? "first: " + params.first : "null";
    const lastParams = params.last ? ", last: " + params.last : "";
    const afterParams = params.after ? ", after: " + `"${params.after}"` : "";
    const beforeParams = params.before
      ? ", before: " + `"${params.before}"`
      : "";

    const queryParams = firstParams + lastParams + afterParams + beforeParams;

    const campaignAttributes = jobListAttributes
      .replace(/\bgptSummary\b\n?/g, "")
      .trim();

    const payload = {
      query: `{
        marketingCampaign(slug:"${params.slug}") {
          campaignDescription
          campaignName
          id
          imageUrl
          slug
          jobs(${queryParams}) {
            pageInfo{
              startCursor
              endCursor
              hasNextPage
              hasPreviousPage
            }
            nodes {
            ${campaignAttributes}
          }
        }
      }
    }
  `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload, true)
      .then(async (response) => {
        if (response.status === 200) {
          if (response?.data?.data?.marketingCampaign) {
            const campaignObj = response?.data?.data?.marketingCampaign;

            const campaign = {
              campaignDescription: campaignObj.campaignDescription,
              campaignName: campaignObj.campaignName,
              id: campaignObj.id,
              imageUrl: campaignObj.imageUrl,
              slug: campaignObj.slug,
            };

            const pageInfo = campaignObj?.jobs?.pageInfo;
            const jobs = campaignObj?.jobs?.nodes;

            return dispatch({
              type: types.FETCH_CAMPAIGN_JOBS_SUCCEED,
              payload: {
                campaign: campaign,
                pageInfo: pageInfo,
                jobs: jobs,
              },
            });
          }
        }
        return dispatch({
          type: types.FETCH_CAMPAIGN_JOBS_FAILED,
        });
      });
  };

const getFooterCompanyWriteup = async (params = {}) => {
  const payload = {
    query: `
    {
      footerWriteup {
        data {
          attributes {
            title
            content
          }
        }
      }
    }    
    `,
  };

  const response = await strapiCall(payload);

  if (response.status === 200) {
    const { data } = response?.data?.data?.footerWriteup || {};

    return data ?? null;
  } else {
    return null;
  }
};

const getCmsSpecialisations = async (params = {}) => {
  const payload = {
    query: `
    {
      specialisations {
        data {
          id
          attributes {
            name
            slug
            writeup
          }
        }
      }
    }    
    `,
  };

  const response = await strapiCall(payload);

  if (response.status === 200) {
    const { data } = response?.data?.data?.specialisations || {};

    return data ?? null;
  } else {
    return null;
  }
};

const getCmsLocations = async (params = {}) => {
  const payload = {
    query: `
    {
      stateRegions(filters: { country: { eqi: "${process.env.NEXT_PUBLIC_JSW_GEOLOCATION}"  } }, sort: ["id"]) {
        data {
          attributes {
            location
            slug
          }
        }
      }
    }    
    `,
  };

  const response = await strapiCall(payload);

  if (response.status === 200) {
    const { data } = response?.data?.data?.stateRegions || {};

    return data ?? null;
  } else {
    return null;
  }
};

const getCmsSubSpecialisation = async (params = {}) => {
  const payload = {
    query: `
    {
      subSpecialisations(filters: { slug: { eqi: "${params.keyword}" } }) {
        data {
          attributes {
            name
            writeup
          }
        }
      }
    }    
    `,
  };

  const response = await strapiCall(payload);

  if (response.status === 200) {
    const { data } = response?.data?.data?.subSpecialisations || {};

    return Array.isArray(data) && data.length > 0 ? data[0] : null;
  }
};

const triggerJobReload = (status) => async (dispatch) => {
  return dispatch({
    type: types.TRIGGER_JOB_RELOAD,
    jobReload: status,
  });
};

const updateLoadingJobs = (status) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_LOADING_JOBS,
    status: status,
  });
};

const updateJobListTab = (tab) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_JOB_LIST_TAB,
    tab: tab,
  });
};

const updateFypTab = (tab) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_FYP_TAB,
    tab: tab,
  });
};

const updateViewHistory = (state) => async (dispatch) => {
  return dispatch({
    type: types.UPDATE_VIEW_HISTORY,
    state: state,
  });
};

const updateHideJob =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.HIDE_JOB });

    const jobId = params.jobId ?? "";
    const rejectionReason = params.rejectionReason ?? "";

    const payload = {
      query: `
        mutation {
          rejectJob(input: {jobId: "${jobId}", rejectionReason: "${rejectionReason}"}) {
            rejectJob {
              jobId
            }
          }
        }     
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { rejectJob } = response?.data?.data?.rejectJob;

          if (rejectJob != null) {
            return dispatch({
              type: types.HIDE_JOB_SUCCESS,
            });
          }
          if (rejectJob === null) {
            return dispatch({
              type: types.HIDE_JOB_FAILED,
            });
          }
        } else {
          return dispatch({
            type: types.HIDE_JOB_FAILED,
          });
        }
      });
  };

const undoHideJob =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.UNDO_HIDE_JOB });

    const payload = {
      query: `
          mutation {
            undoRejection(input: {jobId: "${params.jobId}"}) {
              success
        }
      }
    `,
    };
    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { success } = response?.data?.data?.undoRejection;
          if (success) {
            return dispatch({
              type: types.UNDO_HIDE_JOB_SUCCESS,
            });
          }
        } else {
          return dispatch({
            type: types.UNDO_HIDE_JOB_FAILED,
          });
        }
      });
  };

const updateFeedbackHideJob =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: types.FEEDBACK_HIDE_JOB });

    const jobId = params.jobId ?? "";
    const rejectionReason = params.rejectionReason ?? "";

    const payload = {
      query: `
          mutation {
            updateRejectionReason(input: {jobId: "${jobId}",rejectionReason: "${rejectionReason}"}) {
              rejectJob {
                id
                rejectionReason
              }
            }
          }
        `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { rejectJob } = response?.data?.data?.updateRejectionReason;
          if (rejectJob != null) {
            return dispatch({
              type: types.FEEDBACK_HIDE_JOB_SUCCESS,
            });
          }
        } else {
          return dispatch({
            type: types.FEEDBACK_HIDE_JOB_FAILED,
          });
        }
      });
  };

export {
  applyJob,
  applyJobs,
  bookmarkJob,
  bookmarkSingleJobCompany,
  bulkApplyJobs,
  cancelJobApplication,
  clearAppliedJobs,
  clearBookmarkJobList,
  clearBulkAppliedJobs,
  clearJobAlerts,
  clearJobList,
  clearJobSuggestion,
  clearPendingJobs,
  clearSearchSuggestions,
  closeAshleyErrorDialog,
  createJobAlert,
  deleteJobAlert,
  fetchAppliedJobs,
  fetchJobBookmarkList,
  fetchPendingJobs,
  fetchSearchSuggestions,
  getAshleyAwsRecommendedJobs,
  getAshleyRecommendedJobs,
  getBulkJob,
  getCmsLocations,
  getCmsSpecialisations,
  getCmsSubSpecialisation,
  getCuratedJobs,
  getExperienceLevels,
  getExperienceLevelsWithoutRedux,
  getFeaturedJobs,
  getFooterCompanyWriteup,
  getHomepageAds,
  getHomepageLogo,
  getJobAlert,
  getJobAlerts,
  getJobAppliedAndBookmarkStatus,
  getJobs,
  getJobsCampaign,
  getJobsForCima,
  getJobsForJobsfeed,
  getJobsForSitemap,
  getJobSimilarJobsV1,
  getJobsMicrosite,
  getJobsParams,
  getJobsWithoutRedux,
  getJobTrackSlugWithoutRedux,
  getJobTypes,
  getJobTypesWithoutRedux,
  getJobV1,
  getJobWithoutRedux,
  getPostApplyRecommendedJobs,
  getPostApplyRecommendedJobsBoost,
  getPostApplyRecommendedJobsWithoutRedux,
  getRecentlyViewedJobs,
  getRecommendedJobs,
  getSpecialisation,
  getSpecialisationWithoutRedux,
  getStateRegions,
  getStateRegionWithoutRedux,
  getTracks,
  getTracksWithoutRedux,
  getTrendingChip,
  getTrendingJobKeywords,
  postGaSessionId,
  replaceJobAlert,
  saveJob,
  triggerJobReload,
  unBookmarkJob,
  unBookmarkSingleJobCompany,
  undoHideJob,
  unSaveJob,
  updateAshleyInfoDialog,
  updateBookmarkedJobInList,
  updateBookmarkedRecommendedJobs,
  updateCheckingUrlParamForJobFilterStatus,
  updateFeedbackHideJob,
  updateFetchingForYou,
  updateFetchingJobListStatus,
  updateForYouPaginationCounter,
  updateFypTab,
  updateHideJob,
  updateHoverJob,
  updateInternshipFilterButtonStatus,
  updateJobAlert,
  updateJobAlertEmail,
  updateJobAlertToggle,
  updateJobId,
  updateJobList,
  updateJobListFilter,
  updateJobListFilterLanding,
  updateJobListPage,
  updateJobListSearchKeyword,
  updateJobListTab,
  updateJobSearchKeyword,
  updateLoadingJobs,
  updateSearchFeedback,
  updateSearchType,
  updateSelectAllJobFilter,
  updateSelectedJob,
  updateSelectedJobId,
  updateShowJobAlertDialog,
  updateShowJobAlertSuccessDialog,
  updateViewHistory,
  visitorAddJobAlert,
  visitorUnsubscribeJobAlert,
};
