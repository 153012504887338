import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";
import { FontSize } from "../../../assets/font";
import * as config from "../../../config/config";

// Grid Components //
export const DiscoverCompaniesContainer = styling(Grid)(({ theme }) => ({
  backgroundImage: `url(${config.assetDomain}/images/hiredly/homepage/discovery-makeover-guys-banner-desktop.webp)`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  width: "80%",
  height: "500px",
  borderRadius: "20px",
  margin: "0 auto 0px auto",
  maxWidth: "1026px",
  backgroundColor: Color.homepageGrey,
  [theme.breakpoints.down("tabletL")]: {
    backgroundImage: `url(${config.assetDomain}/images/hiredly/homepage/discovery-makeover-guys-banner-desktop.webp)`,
  },
  [theme.breakpoints.down("mobileL")]: {
    backgroundImage: `url(${config.assetDomain}/images/hiredly/homepage/discovery-makeover-guys-banner-mobile.webp)`,
  },
}));

export const OuterContainer = styling(Grid)({
  backgroundColor: Color.homepageGrey,
});

export const DecoyGrid = styling(Grid)({
  minHeight: "120px",
  backgroundColor: Color.homepageGrey,
  margin: "0 auto 0px auto",
  position: "static",
  maxWidth: "1026px",
});

export const GradientOverlay = styling(Grid)(({ theme }) => ({
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  padding: "50px",
  borderRadius: "20px",
  background: `linear-gradient(
        to bottom right,
        rgba(81, 42, 204, 0.8),
        rgba(0, 0, 0, 0) 60%
      )`,
  [theme.breakpoints.down("tablet")]: {
    paddingTop: "30px",
    paddingRight: "25px",
  },
}));

export const TitleContainer = styling(Grid)(({ theme }) => ({
  width: "50%",
  marginBottom: "20px",
  [theme.breakpoints.down("tablet")]: {
    width: "100%",
  },
}));

// Typography Components
export const TitleStyled = styling(Typography)(({ theme }) => ({
  fontSize: FontSize.desktopDisplay2,
  fontWeight: FontSize.bold,
  color: Color.white,
  lineHeight: 1.2,
  [theme.breakpoints.down("tablet")]: {
    fontSize: FontSize.desktopHeader,
  },
}));

export const LinkStyled = styling(Link)(({ theme }) => ({
  textDecoration: "none",
}));
